import React, { useEffect, useRef } from "react";
import noSignal from "./no-signal";
import Stream from "./stream";
import { css } from "@emotion/css";

interface PreviewProps {
  src?: Stream;
  label: string;
  onClick: () => void;
}

const Preview: React.FC<PreviewProps> = ({
  src,
  label,
  onClick,
}): JSX.Element => {
  const vidRef = useRef<HTMLVideoElement>(null);
  const prevRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!src) return;

    vidRef.current!.srcObject = src.mediaStream;
    vidRef.current!.play();
  }, [src, vidRef]);

  useEffect(() => {
    if (!prevRef.current) return;
    const ctx = prevRef.current.getContext("2d");
    if (!ctx) return;
    noSignal(prevRef.current, ctx, label);
  }, [label]);

  return (
    <div
      className={css`
        position: relative;
      `}
    >
      {!src ? (
        <canvas
          ref={prevRef}
          className={css`
            aspect-ratio: 16 / 9;
            width: 100%;
            position: relative;
            z-index: 0;
          `}
        />
      ) : (
        <video
          ref={vidRef}
          autoPlay
          muted
          playsInline
          onClick={() => {
            onClick();
          }}
          className={css`
            width: 100%;
            aspect-ratio: 16 / 9;
            position: relative;
            z-index: 0;
          `}
        />
      )}
      <div
        className={css`
          top: 0;
          right: 0;
          position: absolute;
          color: #fff;
          background: #000;
          padding: 10px;
          text-align: center;
          z-index: 1;
        `}
      >
        {label}
      </div>
    </div>
  );
};

export default Preview;
