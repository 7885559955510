import React, { useState } from "react";
import Multiview from "./multiview";

function App() {
  const [braveUrl, setBraveUrl] = useState("");
  const [loadMultiview, setLoadMultiview] = useState(false);

  if (!loadMultiview)
    return (
      <>
        <h1>Webmix</h1>
        <p>Enter an address of a Brave instance to connect too</p>
        <input
          type="text"
          placeholder="localhost:8443"
          onChange={(e) => {
            setBraveUrl(e.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") setLoadMultiview(true);
          }}
        />
        <button onClick={() => setLoadMultiview(true)}>Connect</button>
      </>
    );

  return <Multiview src={braveUrl} />;
}

export default App;
