import { Output } from "./brave";
import WebRTC from "./webrtc";

class Stream {
  id: number;
  src: string;
  private webrtc: WebRTC;

  mediaStream: MediaStream | null = null;

  constructor(src: Output, socketUrl: string) {
    this.id = src.id;
    this.src = src.source;
    this.webrtc = new WebRTC(src.id, socketUrl);
    this.webrtc.onMediaStream = () =>
      (this.mediaStream = this.webrtc.mediaStream);
  }
}

export default Stream;
