import Stream from "./stream";

class Brave {
  braveUrl: string;
  socketUrl: string;
  ws: WebSocket;

  cpuUsage: number = 0;

  inputs: Input[] = [];
  outputs: Output[] = [];
  streams: Stream[] = [];
  mixers: Mixer[] = [];
  currentMixer?: Mixer;
  programStream?: string;

  onInputUpdate?: () => void;
  onPreviewUpdate?: () => void;
  onMixerUpdate?: () => void;
  onCpuUpdate?: () => void;

  constructor(braveUrl: string) {
    const socketProtocol =
      window.location.protocol === "https:" ? "wss:" : "ws:";
    this.braveUrl = `${window.location.protocol}//${braveUrl}`;
    this.socketUrl = `${socketProtocol}//${braveUrl}/socket`;

    this.ws = new WebSocket(this.socketUrl);

    this.ws.onerror = (event) => console.error("not pog", event);
    this.ws.onclose = (event) =>
      console.warn("WebSocket connection closed, retrying...", event);

    this.ws.onmessage = (event) => {
      let dataParsed = JSON.parse(event.data) as Event;

      if (isPing(dataParsed)) this.handlePing(dataParsed);
      else if (isUpdate(dataParsed)) this.handleUpdate(dataParsed);
      else if (isDelete(dataParsed)) this.handleDelete(dataParsed);
    };

    this.getAll()
      .then((all) => {
        all.inputs.forEach((input) => {
          this.inputs.push(input);
        });
        if (this.onInputUpdate) this.onInputUpdate();

        all.outputs.forEach((output) => {
          this.outputs.push(output);
        });

        all.mixers.forEach((mixer) => {
          this.mixers.push(mixer);
        });
        if (this.onMixerUpdate) this.onMixerUpdate();
      })
      .catch((err) => {
        console.error("failed to fetch existing state", err);
      });
  }

  close = () => this.ws.close();

  createMixer = () => {
    return new Promise<Mixer>((resolve, reject) => {
      fetch(`${this.braveUrl}/api/mixers`, {
        method: "put",
        body: JSON.stringify({
          width: 1920,
          height: 1080,
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.error) return reject(json.error);
          return resolve(json);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  setMixer = (mixer: Mixer) => {
    this.currentMixer = mixer;
    mixer.sources.forEach((source) => {
      if (source.in_mix) this.programStream = source.uid;
    });
  };

  cutToProgram = (stream: Stream) => {
    if (!this.currentMixer) return;
    return new Promise<Output>((resolve, reject) => {
      fetch(
        `${this.braveUrl}/api/mixers/${this.currentMixer!.id}/cut_to_source`,
        {
          method: "post",
          body: JSON.stringify({
            uid: stream.src,
          }),
        }
      ).catch((err) => {
        return reject(err);
      });
    });
  };

  private handlePing = (ping: Ping) => {
    this.cpuUsage = ping.cpu_percent;
    if (this.onCpuUpdate) this.onCpuUpdate();
  };

  // A block (input, output, mixer, overlay) has been updated
  private handleUpdate = (update: Update) => {
    if (isInputUpdate(update)) {
      this.inputs = this.inputs.filter((input) => input.id !== update.data.id);
      this.inputs.push(update.data);
      this.inputs.sort((a, b) => a.id - b.id);
      if (!this.onInputUpdate) return;
      this.onInputUpdate();
    }
    if (isMixerUpdate(update)) {
      this.mixers = this.mixers.filter((mixer) => mixer.id !== update.data.id);
      this.mixers.push(update.data);
      this.mixers.sort((a, b) => a.id - b.id);
      if (!this.onMixerUpdate) return;
      this.onMixerUpdate();
    }
  };

  // A block (input, output, mixer, overlay) has been deleted
  private handleDelete = (del: Delete) => {
    if (isInputDelete(del)) {
      this.inputs = this.inputs.filter((input) => input.id !== del.id);
      if (!this.onInputUpdate) return;
      this.onInputUpdate();
    }
  };

  requestPreview = (input: Input) => {
    this.findOrCreateOutput(input)
      .then((output) => {
        this.streams.push(new Stream(output, this.socketUrl));
        if (this.onPreviewUpdate) this.onPreviewUpdate();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  private findOrCreateOutput = (input: Input): Promise<Output> => {
    let outputs = this.outputs.filter((output) => {
      return output.type === "webrtc" && output.source === input.uid;
    });
    if (outputs.length === 0) {
      return this.createOutput(input);
    }
    // We'll just take the first valid output.
    // TODO: Handle output states i.e. set to play if it isn't.
    return new Promise<Output>((resolve) => {
      return resolve(outputs[0]);
    });
  };

  private createOutput = (input: Input): Promise<Output> => {
    return new Promise<Output>((resolve, reject) => {
      fetch(`${this.braveUrl}/api/outputs`, {
        method: "put",
        body: JSON.stringify({
          type: "webrtc",
          source: input.uid,
        }),
      })
        .then((response) => response.json())
        .then((output: Output) => {
          output.type = "webrtc";
          output.source = input.uid;
          this.outputs.push(output);
          return resolve(output);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  private getAll = (): Promise<getAllResponse> => {
    return new Promise<getAllResponse>((resolve, reject) => {
      fetch(`${this.braveUrl}/api/all`)
        .then((response) => response.json())
        .then((data: getAllResponse) => {
          return resolve(data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };
}

export default Brave;

interface getAllResponse {
  inputs: Input[];
  outputs: Output[];
  mixers: Mixer[];
}

interface Event {
  msg_type: "ping" | "update" | "delete";
}

const isPing = (event: Event): event is Ping => event.msg_type === "ping";
interface Ping {
  msg_type: "ping";
  cpu_percent: number;
}

const isUpdate = (event: Event): event is Update => event.msg_type === "update";
interface Update {
  msg_type: "update";
  block_type: "input" | "output" | "overlay" | "mixer";
}

const isDelete = (event: Event): event is Delete => event.msg_type === "delete";
interface Delete {
  msg_type: "delete";
  block_type: "input" | "output" | "overlay" | "mixer";
}

const isInputUpdate = (update: Update): update is InputUpdate =>
  update.block_type === "input";
interface InputUpdate {
  msg_type: "update";
  block_type: "input";
  data: Input;
}

export interface Input {
  id: number;
  uid: string;
  state: "NULL" | "READY" | "PAUSED" | "PLAYING";
  desired_state: "NULL" | "READY" | "PAUSED" | "PLAYING";
}

interface InputDelete {
  msg_type: "delete";
  block_type: "input";
  id: number;
}

const isInputDelete = (del: Delete): del is InputDelete =>
  del.block_type === "input";
export interface Output {
  id: number;
  uid: string;
  state: "NULL" | "READY" | "PAUSED" | "PLAYING";
  source: string;
  type: "rtmp" | "tcp" | "file" | "image" | "webrtc" | "kvs" | "local";
}

interface MixerSource {
  uid: string;
  in_mix: boolean;
}

export interface Mixer {
  id: number;
  uid: string;
  sources: MixerSource[];
}

const isMixerUpdate = (update: Update): update is MixerUpdate =>
  update.block_type === "mixer";
interface MixerUpdate {
  msg_type: "update";
  block_type: "mixer";
  data: Mixer;
}
