import React, { useEffect, useState } from "react";
import Brave, { Input, Mixer } from "./brave";
import Preview from "./preview";
import { css } from "@emotion/css";
import Stream from "./stream";

interface MultiviewProps {
  src: string;
}

const Multiview: React.FC<MultiviewProps> = (props): JSX.Element => {
  const [programStream, setProgramStream] = useState<Stream>();
  const [preview, setPreview] = useState<Stream>();
  const [inputs, setInputs] = useState<Input[]>([]);
  const [previews, setPreviews] = useState<Stream[]>([]);
  const [mixers, setMixers] = useState<Mixer[]>([]);
  const [cpuUsage, setCpuUsage] = useState(0); // Temporarily disabled due to high CPU usage.
  const [brave] = useState(() => new Brave(props.src));
  const [monitors, setMonitors] = useState<Monitor[]>([]);

  const updateMonitorInput = (monitorID: number, inputID?: number) => {
    monitors.forEach((monitor, i) => {
      if (monitor.id === monitorID) {
        monitor.inputID = inputID;
        setMonitors((existingItems) => {
          return [
            ...existingItems.slice(0, i),
            (existingItems[i] = monitor),
            ...existingItems.slice(i + 1),
          ];
        });
      }
    });
  };

  const updateMonitorLabel = (monitorID: number, label: string) => {
    if (label === "") label = "Source " + monitorID.toString();
    monitors.forEach((monitor, i) => {
      if (monitor.id === monitorID) {
        monitor.label = label;
        setMonitors((existingItems) => {
          return [
            ...existingItems.slice(0, i),
            (existingItems[i] = monitor),
            ...existingItems.slice(i + 1),
          ];
        });
      }
    });
  };

  useEffect(() => {
    const monitors = [];

    for (let i = 0; i < 8; i++) {
      monitors[i] = { id: i + 1, label: "Source " + (i + 1).toString() };
    }
    setMonitors(monitors);
  }, []);

  useEffect(() => {
    brave.onInputUpdate = () => setInputs(brave.inputs);
    // brave.onCpuUpdate = () => setCpuUsage(brave.cpuUsage);
    brave.onMixerUpdate = () => setMixers(brave.mixers);
    brave.onPreviewUpdate = () => setPreviews(brave.streams);
  }, [brave]);

  const setProgram = (stream?: Stream) => {
    if (stream) brave.cutToProgram(stream);
    setProgramStream(stream);
    setPreview(programStream);
  };

  return (
    <>
      <nav
        className={css`
          ${row}
          padding-left: 0.5rem;
          background-color: hotpink;
        `}
      >
        <h1
          className={css`
            align-self: stretch;
          `}
        >
          Webmix
        </h1>
        <div
          className={css`
            margin-left: auto;
            padding-right: 0.5rem;
            align-self: stretch;
            display: flex;
            flex-direction: row;
          `}
        >
          <button onClick={() => {
            brave.createMixer();
          }}>Create mixer</button>
          <h4>Mixer:</h4>
          <select
            className={css`margin-left: 1rem; margin-right 1rem;`}
            name="mixer"
            id="mixer"
            onChange={(e) => {
              brave.mixers.forEach((mixer) => {
                if (mixer.id.toString() === e.target.value) {
                  brave.setMixer(mixer);
                  previews.forEach((preview) => {
                    if (preview.src === brave.programStream)
                      setProgramStream(preview);
                  });
                }
              });
            }}
          >
            <option key="none" value="none">
              None
            </option>
            {mixers.map((mixer) => (
              <option key={mixer.id} value={mixer.id}>
                {mixer.id}
              </option>
            ))}
          </select>
        </div>
        <h4>Server load: {cpuUsage}%</h4>
        <a
          className={css`
            padding-left: 0.5rem;
          `}
          href={window.location.protocol + "//" + props.src}
          target="_blank"
          rel="noreferrer"
        >
          <h4>Configure ↗️</h4>
        </a>
      </nav>
      <div className={gridPrevProg}>
        <Preview
          src={preview}
          label="preview"
          onClick={() => setProgram(preview)}
        />
        <Preview src={programStream} label="program" onClick={() => {}} />
      </div>
      <div className={gridInputs}>
        {monitors.map((monitor) => {
          if (monitor.inputID)
            previews.forEach((preview) => {
              if (preview.src === "input" + monitor.inputID?.toString()) {
                monitor.src = preview;
              }
            });
          else monitor.src = undefined;
          return (
            <Preview
              key={monitor.id}
              src={monitor.src}
              label={monitor.label}
              onClick={() => setPreview(monitor.src)}
            />
          );
        })}
      </div>
      <h3>Source mapping</h3>
      <ul>
        {monitors.map((monitor) => (
          <li key={monitor.id}>
            Mon {monitor.id}{" "}
            <input
              type="text"
              placeholder="Label"
              onChange={(e) => {
                updateMonitorLabel(monitor.id, e.target.value);
              }}
            />
            {" Src "}
            <select
              onChange={(e) => {
                if (e.target.value === "none") updateMonitorInput(monitor.id);
                inputs.forEach((input) => {
                  if (input.id.toString() === e.target.value) {
                    updateMonitorInput(monitor.id, input.id);
                    brave.requestPreview(input);
                  }
                });
              }}
            >
              <option key="none" value="none">
                None
              </option>
              {inputs.map((input) => (
                <option key={input.id} value={input.id}>
                  {`${input.id} - ${input.state}`}
                </option>
              ))}
            </select>
          </li>
        ))}
      </ul>
    </>
  );
};

interface Monitor {
  id: number;
  label: string;
  inputID?: number;
  src?: Stream;
}

const row = css`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  box-sizing: border-box;
`;

const gridPrevProg = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const gridInputs = css`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;

export default Multiview;
