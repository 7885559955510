class WebRTC {
  private ws: WebSocket;
  private conn?: RTCPeerConnection;
  private config?: RTCConfiguration;

  mediaStream: MediaStream | null = null;
  onMediaStream?: () => void;

  volume: Volume = {
    msg_type: "volume",
    channels: 0,
    data: [],
  };

  constructor(OutputID: number, socketUrl: string) {
    this.ws = new WebSocket(socketUrl);

    this.ws.onerror = (event) => {
      console.error("not pog (webrtc style)", event);
    };
    this.ws.onclose = (event) => {
      console.warn("WebSocket connection closed, retrying...", event);
    };

    this.ws.onmessage = (event) => {
      let dataParsed = JSON.parse(event.data) as Event;

      if (isWebRTCInit(dataParsed)) {
        let config: RTCConfiguration = {
          iceServers: dataParsed.ice_servers,
        };
        this.config = config;
      } else if (dataParsed.ice != null) {
        this.onIncomingICE(dataParsed.ice);
      } else if (dataParsed.sdp != null) {
        this.onIncomingSDP(dataParsed.sdp);
      } else if (isVolume(dataParsed)) {
        this.volume = dataParsed;
        return;
      }
    };

    this.ws.onopen = () => {
      this.requestWebRTCConn(OutputID);
    };
  }

  private requestWebRTCConn(outputID: number) {
    this.ws.send(
      JSON.stringify({
        msg_type: "webrtc-init",
        output_id: outputID,
      })
    );
  }

  private createCall() {
    this.conn = new RTCPeerConnection(this.config);

    this.conn.oniceconnectionstatechange = () => {
      console.log("ICE connection state: %s", this.conn!.iceConnectionState);
    };

    this.conn.onicecandidate = (event) => {
      if (event.candidate) {
        console.log("ICE candidate:", event.candidate);
        this.ws.send(JSON.stringify({ ice: event.candidate }));
      }
    };

    this.conn.ontrack = (event) => {
      event.streams.forEach((stream) => {
        if (stream.getVideoTracks().length > 0) {
          this.mediaStream = stream;
          if (this.onMediaStream) this.onMediaStream();
        }
      });
    };
  }

  private onIncomingICE(ice: RTCIceCandidateInit) {
    this.conn!.addIceCandidate(new RTCIceCandidate(ice));
  }

  // SDP offer received from peer, set remote description and create an answer
  private onIncomingSDP(sdp: RTCSessionDescriptionInit) {
    this.createCall();
    this.conn!.setRemoteDescription(sdp).then(() => {
      if (sdp.type !== "offer") return;
      this.conn!.createAnswer().then((clientSDP) => {
        this.conn!.setLocalDescription(clientSDP).then(() => {
          this.ws.send(JSON.stringify({ sdp: clientSDP }));
        });
      });
    });
  }

  close() {
    if (!this.conn) return;
    this.conn.close();
    this.ws.send(JSON.stringify({ msg_type: "webrtc-close" }));
    this.ws.close();
  }
}

export default WebRTC;

interface Event {
  msg_type: "webrtc-initialising" | "volume";
  sdp?: RTCSessionDescriptionInit;
  ice?: RTCIceCandidateInit;
}

const isWebRTCInit = (event: Event): event is WebRTCInit => {
  return event.msg_type === "webrtc-initialising";
};

interface WebRTCInit {
  msg_type: "webrtc-initialising";
  ice_servers: IceServer[];
}

interface IceServer {
  urls: string;
}

const isVolume = (event: Event): event is Volume => {
  return event.msg_type === "volume";
};

interface Volume {
  msg_type: "volume";
  channels: number;
  data: string[];
}
