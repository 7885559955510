const noSignal = (
  cnv: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
  label: string
) => {
  const dpi = window.devicePixelRatio;
  // Get current size of the canvas
  const rect = cnv.getBoundingClientRect();
  // Increase the actual size of our canvas
  cnv.width = rect.width * dpi;
  cnv.height = rect.height * dpi;
  // Ensure all drawing operations are scaled
  ctx.scale(dpi, dpi);
  // Scale everything down using CSS
  cnv.style.width = rect.width + "px";
  cnv.style.height = rect.height + "px";

  ctx.fillStyle = "#fff5e6";
  let width = parseFloat(cnv.style.width);
  let height = parseFloat(cnv.style.height);
  let cx = width / 2;
  let cy = height / 2;

  ctx.font = "30px Helvetica";
  const text = "no signal";
  const textSize = ctx.measureText(text).width;
  const labelSize = ctx.measureText(label).width;

  let drawOneFrame = function (time: number) {
    let angle = Math.PI * 2 * (time / 5000);
    ctx.save();
    ctx.fillRect(0, 0, width, height);

    ctx.translate(cx, cy);

    ctx.fillStyle = "hsl(" + angle * 40 + ",80%,50%)";
    ctx.fillRect(-textSize / 2, 10, textSize, 25);

    ctx.fillStyle = "black";
    ctx.fillText(text, -textSize / 2, 0);

    ctx.fillText(label, -labelSize / 2, 160);

    ctx.restore();
    window.requestAnimationFrame(drawOneFrame);
  };
  window.requestAnimationFrame(drawOneFrame);
};

export default noSignal;